<template>
  <div class="cart_container">
    <div class="content">
      <div class="top flex_sp_c">
        <div>
          <span class="c3">我的购物车</span>
          <span class="c9">
            （订单满 <span style="color: #e91e10">{{ info.byje }}</span> 元包邮）
          </span>
        </div>
        <div class="flex_row c9 step">
          <div class="cart_item">
            <span class="color_theme">我的购物车</span>
            <div class="green">
              <span>1</span>
              <div class="line active_bg"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>核对订单信息</span>
            <div class="grey">
              <div class="line1"></div>
              <span>2</span>
              <div class="line"></div>
            </div>
          </div>
          <div class="cart_item">
            <span>成功提交订单</span>
            <div class="grey">
              <div class="line1"></div>
              <span>3</span>
            </div>
          </div>
        </div>
      </div>
      <el-table :data="tableData" ref="multipleTable" :cell-style="tableRowStyle" @select="select" @select-all="selectAll">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="date" label="商品信息" align="left" width="350">
          <template slot-scope="{ row }">
            <div class="flex_row_aic">
              <img style="width: 87px; height: 87px" :src="row.image.url" alt="" />
              <div class="info">
                <p class="shenglue_1 bold">{{ row.title }}</p>
                <p>{{ row.manufacturer }}</p>
                <p>{{ row.specification }}</p>
                <p>{{ row.validity_date }}</p>
                <tagcom :tags="row.tags" :poi="'bottom'"></tagcom>
              </div>
            </div>
            <div class="color_theme">{{ row.show_memo }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="price_str" label="会员价" align="center" /> -->
        <el-table-column label="会员价" align="center">
          <template slot-scope="{ row }">
            <span class="f14 mr_10">{{ row.price_str }}</span>
            <span class="f12" style="text-decoration: line-through">{{ row.price1 }} </span>
          </template>
        </el-table-column>
        <el-table-column label="数量" align="center" width="150">
          <template slot-scope="{ row }">
            <el-input-number
              size="mini"
              :min="Number(row.min_number)"
              :max="Number(row.max_number)"
              :step="Number(row.step)"
              v-model="row.number"
              @change="handleChange($event, row)"
              @blur="blur(row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <template slot-scope="{ row }">
            <span class="f16"> ￥{{ (Number(row.number) * Number(row.price)).toFixed(2) }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <span class="c_p" @click="handleDelete(row.id)">删除</span>
            <span class="c_p ml_20" @click="handleCollect(row.id)">加入收藏</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="tips_box c3">
        单笔订单必须大于
        <span class="red">{{ info.qfsl }}</span> 个品种才能进行结算，单笔订单满
        <span class="red">{{ info.qfje }}</span>
        才能进行结算，运费 <span class="red">{{ info.yfje }}</span> ，订单满 <span class="red">{{ info.byje }}</span> 包邮， 药品为特殊商品，若无质量问题不予退货，如需退货需自行承担运费
        <span class="red">20</span>
        元起，产生具体退货运费按照实际为准。您可以收到货物
        <span class="red">30</span>
        天内付款，未完成上一笔付款将无法下单!
      </div>

      <div class="total">
        已选商品共
        <span class="red1"> {{ selectedList.length }}</span
        >件，商品总额（￥{{ total }}），合计
        <span class="red1">￥{{ total }}</span>
      </div>

      <div class="settle flex_sp_c mb_20">
        <div class="flex_row c0">
          <p class="item" @click="$router.push('/index/allGoods')">继续购物</p>
          <p class="item" @click="clear">清空购物车</p>
        </div>
        <div class="flex_row_aic c0">
          <p>总计（不含运费）：</p>
          <p class="red mr_20">￥{{ total }}</p>
          <div class="btn flex_c_c cf" @click="submit">
            <span>去结算</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <recommend />
    </div>
  </div>
</template>

<script>
import recommend from "./recommend.vue";
import tagcom from "@/components/tag.vue";

import { handleData } from "@/utils";
export default {
  components: {
    recommend,
    tagcom,
  },
  data() {
    return {
      tableData: [],
      selectedList: [],
      info: {},
    };
  },
  created() {
    this.getCartList();
    this.$api("cart.getAttr").then((res) => {
      this.info = res.data;
    });
  },
  computed: {
    total() {
      let num = 0;
      this.selectedList.forEach((e) => {
        num += Number(e.number) * Number(e.price);
      });
      return num.toFixed(2);
    },
  },
  methods: {
    getCartList() {
      this.$api("cart.getCartList").then((res) => {
        this.tableData = res.data;
        this.selectedList = [];
        this.tableData.forEach((e) => {
          if (e.sel == 101) {
            this.selectedList.push(e);
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(e, true);
            });
          }
        });
      });
    },
    tableRowStyle({ columnIndex }) {
      if (columnIndex == 2 || columnIndex == 4) {
        return "color:#ff4c4c;";
      }
    },
    async handleDelete(id) {
      let p = [{ id }];
      await handleData("cart.delCart", { goods: p }, "删除数据");
      this.getCartList();
      this.$store.dispatch("getBadge");
    },
    handleCollect(id) {
      this.$api("goods.addCollect", { id }).then(() => {
        this.$message.success("收藏成功");
      });
    },
    async clear() {
      let arr = [];
      this.tableData.forEach((e) => {
        arr.push({ id: e.id });
      });
      await handleData("cart.delCart", { goods: arr }, "清空购物车");
      this.getCartList();
      this.$store.dispatch("getBadge");
      this.selectedList = [];
    },
    handleChange(currentValue, item) {
      console.log(currentValue, item);
      let number = item.number;
      if (item.step != 1) {
        let num = Number(item.number) / Number(item.step);

        if (num % 1 !== 0) {
          number = Number(item.step) * (parseInt(num) + 1);
        }
      }

      this.$api("cart.updateNum", { id: item.id, number }).then(() => {
        this.getCartList();
      });
    },
    blur(item) {
      if (item.step != 1) {
        let num = Number(item.number) / Number(item.step);

        if (num % 1 !== 0) {
          item.number = Number(item.step) * (parseInt(num) + 1);
        }
        this.tableData.forEach((e) => {
          if (e.id == item.id) e = item;
        });
      }
    },
    select(selection, row) {
      this.selectedList = selection;
      let p = [{ id: row.id, sel: row.sel == 101 ? 102 : 101 }];
      this.$api("cart.cartChoose", { goods: p }).then(() => {});
    },
    selectAll(selection) {
      this.selectedList = selection;
      let p = [];
      this.tableData.forEach((v) => {
        if (selection.length == this.tableData.length) {
          p.push({ id: v.id, sel: 101 });
        } else {
          p.push({ id: v.id, sel: 102 });
        }
      });

      this.$api("cart.cartChoose", { goods: p }).then(() => {});
    },
    submit() {
      if (!this.selectedList.length) {
        this.$message.error("请选择结算商品");
        return;
      }
      this.$api("cart.submitCart", { goods: this.selectedList }).then((res) => {
        console.log(res);
        this.$router.push({
          name: "CartCheck",
          params: { id: res.data.id },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cart_container {
  background-color: #fff;
  .top {
    width: 100%;
    height: 74px;
    .c3 {
      font-weight: bold;
    }
  }
  .info {
    text-align: left;
    margin-left: 10px;
    .tag {
      padding: 1px 8px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .step {
    width: 360px;
  }
  .cart_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    &:last-child {
      margin: 0;
    }
    .green {
      margin-top: 10px;
      width: 18px;
      height: 18px;
      background: @themeColor;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
    }
    .grey {
      margin-top: 10px;
      width: 18px;
      height: 18px;
      background: #c6c6c6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
    }
    .line {
      width: 51px;
      height: 3px;
      background-color: #e8e8e8;
      position: absolute;
      left: 18px;
    }
    .line1 {
      width: 51px;
      height: 3px;
      background-color: #e8e8e8;
      position: absolute;
      right: 18px;
    }
    .active_bg {
      background-color: @themeColor;
    }
  }
  .tips_box {
    width: 709px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    line-height: 1.6;
    // border-bottom: 1px solid #eeeeee;
  }
  .red {
    color: #f93232;
    font-weight: bold;
  }
  .red1 {
    color: #f93232;
  }
  .total {
    width: 100%;
    height: 50px;
    background: #fff7f0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 120px;
    box-sizing: border-box;
    color: #000;
  }
  .settle {
    width: 100%;
    height: 50px;
    background: #f7f7f7;
    border-bottom: 1px solid #eeeeee;
    .item {
      margin-left: 40px;
      cursor: pointer;
    }
    .btn {
      width: 100px;
      height: 50px;
      background: #f93232;
      font-weight: bold;
      cursor: pointer;
      .el-icon-arrow-right {
        font-size: 15px;
      }
    }
  }
}
</style>
